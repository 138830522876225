.borderLinearGoldGradient {
  border-style: solid;
  border-image: linear-gradient(
      to left,
      rgba(150, 124, 55, 1) 10%,
      rgba(242, 229, 194, 1) 50%,
      rgba(150, 124, 55, 1) 100%
    )
    100% 0 100% 0/3px 0 3px 0 stretch;
}

.borderBottomGoldGradient {
  border-image: linear-gradient(
      to left,
      rgba(150, 124, 55, 1) 10%,
      rgba(242, 229, 194, 1) 50%,
      rgba(150, 124, 55, 1) 100%
    )
    0 0 100% 0/3px 0 3px 0 stretch;
}

.borderGoldCircle {
  border-style: solid;
  border-color: #dbc17d;
}

.borderLinearSilverGradient {
  border-style: solid;
  border-image: linear-gradient(
      to left,
      rgba(86, 86, 80, 1) 10%,
      rgba(217, 217, 217, 1) 50%,
      rgba(86, 86, 80, 1) 100%
    )
    100% 0 100% 0/3px 0 3px 0 stretch;
}

.borderBottomSilverGradient {
  border-image: linear-gradient(
      to left,
      rgba(86, 86, 80, 1) 10%,
      rgba(217, 217, 217, 1) 50%,
      rgba(86, 86, 80, 1) 100%
    )
    0 0 100% 0/3px 0 3px 0 stretch;
}

.borderSilverCircle {
  border-style: solid;
  border-color: #d9d9d9;
}

.borderLinearCopperGradient {
  border-style: solid;
  border-image: linear-gradient(
      to left,
      rgba(117, 33, 7, 1) 10%,
      rgba(156, 65, 37, 1) 50%,
      rgba(123, 38, 12, 1) 100%
    )
    100% 0 100% 0/3px 0 3px 0 stretch;
}

.borderBottomCopperGradient {
  border-image: linear-gradient(
      to left,
      rgba(117, 33, 7, 1) 10%,
      rgba(156, 65, 37, 1) 50%,
      rgba(123, 38, 12, 1) 100%
    )
    0 0 100% 0/3px 0 3px 0 stretch;
}

.borderCopperCircle {
  border-style: solid;
  border-color: #9c4125;
}

.borderLinearGreenGradient {
  border-style: solid;
  border-image: linear-gradient(
      to left,
      #077521 10%,
      #259C41 50%,
      #0C7B26 100%
    )
    100% 0 100% 0/3px 0 3px 0 stretch;
}

.borderBottomGreenGradient {
  border-image: linear-gradient(
      to left,
      #077521 10%,
      #259C41 50%,
      #0C7B26 100%
    )
    0 0 100% 0/3px 0 3px 0 stretch;
}

.borderGreenCircle {
  border-style: solid;
  border-color: #259C41;
}

.btnAction {
  background-color: aliceblue;
  background-size: 100% 100%;
}
