@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;

  @apply bg-black bg-gradient-to-br from-fuchsia-900/25;
}

body {
  font-family: "Barlow Semi Condensed", sans-serif;

  @apply text-neutral-200 text-base leading-6 bg-gradient-to-bl from-blue-900/25 lg:text-lg;
}

.frame {
  @apply max-w-md mx-auto sm:max-w-lg md:max-w-2xl lg:max-w-4xl xl:max-w-5xl px-5; /*  sm:px-8 md:px-12 lg:px-20 xl:px-24 */
}

button,
h1,
h2,
h3,
.btn,
.title {
  @apply uppercase leading-5;
}

h1 {
  @apply text-3xl mb-5 lg:text-4xl;
}

h2 {
  @apply text-2xl mb-4 lg:text-3xl;
}

h3 {
  @apply text-xl mb-4 lg:text-2xl;
}

.title {
  background-image: url(svgs/zog.svg);
  background-size: 5rem; /* given the current graphic */

  @apply mb-7 pb-4 text-3xl text-center bg-bottom bg-no-repeat lg:text-4xl; /* bg-contain */
}

.text-smer,
label {
  @apply text-sm lg:text-base;
}

.text-lger {
  @apply text-lg lg:text-xl;
}

h2,
.title {
  @apply tracking-wider;
}

p a {
  @apply font-bold underline decoration-red-500;
}

button,
.btn,
input,
.input {
  @apply w-full h-12 px-4 text-base rounded-3xl transition-all duration-300 ease-in-out focus:outline-white focus:ring-1 focus:ring-offset-1 disabled:pointer-events-none disabled:opacity-50
}

button,
.btn {
  text-shadow: 0 .05em .09375em rgba(0,0,0,.4);

  @apply flex items-center justify-center font-bold text-center whitespace-nowrap bg-transparent overflow-hidden;
}

input,
.input {
  @apply w-full h-16 px-6 text-xl bg-transparent bg-gradient-to-br from-white/20 to-white/10;
}

.input:disabled,
.input:read-only {
  @apply pointer-events-none;
}

label {
  @apply block mb-1 px-4 font-light;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btn-lg {
  @apply h-16 px-6 text-lg rounded-full;
}

.btn-sm {
  @apply h-8 px-3 text-xs;
}

.btn-xs {
  @apply h-7 px-2 text-xs;
}

.scrolled .btn-circle-scrolled,
.btn-circle {
  @apply w-12 px-2;
}

.btn-sm.btn-circle {
  @apply w-8;
}

.btn-xs.btn-circle {
  @apply w-7;
}

.btn.btn-justify-start {
  @apply justify-start;
}

.btn-rounded-b-sm {
  @apply rounded-b;
}

.btn-rounded-t-sm {
  @apply rounded-t;
}

.btn-boost {
  /* background: linear-gradient(-45deg, #fff45c, #ffbf40, #e58a2e, #ffbf40); */
  background: linear-gradient(-45deg, #ffde5c, #ffa640, #e5772e, #ffa640);
  background-size: 400% 400%;
  animation: gradient 12s ease infinite, shadow 8s ease infinite;

  @apply relative border-2 border-white/40;
}

.btn-boost:before {
  content: '';

  @apply block absolute top-0 right-0 bottom-1/2 left-0 bg-white/8 transition-all ease-in-out duration-500;
}

.btn-boost:hover {
  @apply border-2 border-white/60;
}

.btn-boost:hover:before {
  @apply bg-white/16;
}

.btn-boost span,
.btn-boost svg {
  @apply relative;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes shadow {
  0% {
    box-shadow: 0 0 4rem rgba(128,0,255,.625), 0 0 .375rem rgba(255,255,255.5);
  }
  50% {
    box-shadow: 0 0 2rem rgba(204,0,255,.75), 0 0 .375rem rgba(255,255,255,1);
  }
  100% {
    box-shadow: 0 0 4rem rgba(128,0,255,.625), 0 0 .375rem rgba(255,255,255.5);
  }
}

.btn-ie {
  @apply border border-white/25 hover:bg-gradient-to-br hover:from-white/10 hover:border-white/40;
}
  
.btn-dim {
  @apply font-normal hover:bg-white hover:bg-opacity-10;
}

.btn-dismiss {
  @apply absolute -top-2 -right-2 px-1;
}

.btn-go {
  @apply bg-gradient-to-br from-indigo-600 to-fuchsia-700 hover:from-indigo-500 hover:to-fuchsia-800;
}

.btn-menu {
  @apply h-12 px-5 hover:text-yellow-100;
}

a.btn-menu {
  @apply justify-start bg-gradient-to-b from-transparent to-transparent; /* needs to be defined here, to over-power */
}

.btn-active {
  @apply bg-gradient-to-b from-red-700 to-red-800 shadow-lg;
}

.pt-header {
  @apply pt-4 md:pt-8 lg:pt-16 xl:pt-20;
}

.s {
  @apply py-8 md:py-12 lg:py-20 xl:py-24;
}

.cinema {
  @apply py-12 md:py-16 lg:py-24 xl:py-32 shadow-lg;
}

.cinema-1 {
  background: url(images/cinema-1.jpg) center bottom no-repeat;
  background-size: cover;
}

.knight-shade {
  background: url(svgs/knight-shade.svg) center top no-repeat;
  background-size: contain;
}

.scrolled .no-scrolled {
  @apply hidden;
}

.scrolled .on-scrolled {
  @apply block;
}

.collapse { 
  @apply h-0 opacity-0 invisible overflow-hidden;
}

.collapse.active { 
  @apply h-auto visible opacity-100 transition-all duration-200 ease-in-out;
}
