/*
  modal parent element that buttons interact with
*/

.modal {
  @apply fixed z-40 invisible top-0 left-0 bottom-0 w-full opacity-0 overflow-x-hidden overflow-y-auto transition-all duration-200 ease-in-out;
}

.modal.active {
  @apply opacity-100 visible;
}

/*
  prevent collapse visibility issues inside of closed modals
*/

.modal:not(.active) * {
  visibility: hidden !important;
}

/*
  modal transparent background
*/

.modal-mask {
  backdrop-filter: blur(.5rem);

  @apply fixed top-0 right-0 bottom-0 left-0 w-full cursor-pointer bg-black/75 bg-gradient-to-br from-fuchsia-900/10 overflow-hidden overflow-y-auto;
}

/*
  actual modal content
*/

.modal-view {
  @apply relative invisible mx-auto mb-4 bg-black overflow-hidden shadow-xl opacity-0 transition-all duration-200 ease-in-out sm:max-w-sm md:max-w-md md:mx-auto;
}

.modal-lg .modal-view {
  @apply sm:max-w-lg md:max-w-xl;
}

/*
  animate and reveal when the modal parent element is active
*/

.modal.active .modal-view {
  @apply mt-12 sm:mt-20 opacity-100 visible;
}

/*
  default padding
*/

.p-modal {
  @apply p-5 sm:p-6 md:p-8;
}
